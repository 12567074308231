@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;600&display=swap");

$color-primary: #42b4e7;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Calibri";
    src: url("/assets/fonts/Calibri.woff2") format("woff2"),
      url("/assets/fonts/Calibri.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Calibri";
    src: url("/assets/fonts/Calibri-Bold.woff2") format("woff2"),
      url("/assets/fonts/Calibri-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  html {
    @apply font-calibri;
  }

  body {
    @apply text-body;
  }

  a {
    @apply font-bold;
  }

  // .button-comp to distinguish between Button a tag and normal a tag styling
  a:not(.button-comp) {
    @apply text-primary hover:text-primaryDark;
  }

  // https://github.com/tailwindlabs/tailwindcss-forms/issues/52#issuecomment-884735470
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: $color-primary;
    border-color: var(--tw-ring-color);
  }

  [type="checkbox"]:focus,
  [type="radio"]:focus {
    --tw-ring-color: transparent;
  }

  [type="checkbox"]:checked {
    background-image: url("/assets/images/check-bold.svg");
    background-size: 12px 12px;
  }

  [type="radio"]:checked {
    background-size: 24px;
  }
}

@layer components {
  .dropdown-menu {
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #e7e7e8;
      position: absolute;
      right: calc(1rem - 1px);
      bottom: 100%;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      right: 1rem;
      bottom: 100%;
    }
  }

  .sidebar-toggler {
    &::before {
      content: "";
      position: absolute;
      top: -4px;
      left: -10px;
      width: calc(50% + 12px);
      height: calc(100% + 12px);
      pointer-events: none;
      z-index: -1;

      @apply bg-secondary;
    }
  }

  .main {
    min-width: calc(100% - 80px); // screen width - minified sidebar width
  }

  // input custom class to share style between Input and InputNumber
  .input {
    // pt-11px pb-9px: Because font family is not centered totally
    @apply shadow-formControl rounded-3xl placeholder-main leading-0 w-full pt-11px pb-9px px-4 disabled:border-formControlDisabled disabled:shadow-none focus:border-primary;
  }
}

// dont nest it under @layer else purgeCSS will remove it from prod bundle
// https://v2.tailwindcss.com/docs/optimizing-for-production#removing-all-unused-styles
.react-datepicker {
  @apply flex justify-between flex-wrap;

  &--hide-navigation {
    .react-datepicker {
      &__navigation {
        @apply hidden;
      }
    }
  }

  &--hide-day-names {
    .react-datepicker {
      &__day-names {
        @apply hidden;
      }
    }
  }

  &__month-container {
    @apply flex flex-col mx-auto;
  }

  &__month {
    @apply flex flex-col w-72;
  }

  &__current-month {
    @apply font-semibold text-center pb-4 border-b border-main mb-4 capitalize;
  }

  &__triangle {
    @apply hidden;
  }

  &__week {
    @apply flex justify-around;
  }

  &__day {
    @apply mb-4 w-7 h-7 flex items-center justify-center rounded-full;

    &--outside-month {
      @apply invisible pointer-events-none;
    }

    &--keyboard-selected,
    &--selected {
      @apply bg-primary text-white;
    }

    &--today {
      @apply border border-body;
    }

    &--disabled {
      @apply cursor-not-allowed opacity-25 select-none;
    }
  }
}

@media print {
  .print-display-none {
    display: none;
  }
}
